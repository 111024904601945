<template>
  <div id="ModalDestinoConsulta" class="pa-0">
    <v-card class="container pa-0">
      <v-card-title
        v-bind:style="{ 'background-color': COR_PRINCIPAL }"
        class="pa-0"
      >
        <v-text-field
          id="txtBuscar"
          ref="txtBuscar"
          prepend-inner-icon="mdi-magnify"
          dense
          v-model="search"
          cache-items
          class="ml-3 search-input"
          flat
          dark
          label="Buscar..."
          solo-inverted
          clearable
          tabindex="-1"
        >
        </v-text-field>
      </v-card-title>

      <v-card-text class="wrapper pa-0">
        <v-container class="pa-0">
          <v-data-table
            v-model="selecionado"
            :headers="headers"
            :items="store_Obra.dados"
            single-select
            show-select
            fixed-header
            hide-default-header
            hide-default-footer
            loading-text="Carregando... aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado">
            <template #item="{ item }">
                <tr :search="search">
                  <td class="td-height" >
                    <!-- :value="item.cod_pessoa == store_ModalNegociacao.pessoa_selecionado.cod_pessoa" -->
                    <v-checkbox
                      @change="seleciona($event, item)"
                      :value="item.cod_obra == store_ModalCotacao.destino_selecionado.cod_obra"
                      :key="item.cod_obra"
                      hide-details
                      class="mr-n6"
                      style="margin-top: -50%;"/>
                  </td>
                  <td class="hide-sm py-2" style="width: 99%">
                    <span style="font-size: 15px; display:block; color:#385986 !important;" class="font-weight-bold">
                      {{ item.nome_obra }}
                    </span>
                    <span style="font-size: 12px; display:block; color: #808080 !important" class="mb-0">
                      <span style="font-weight: bold" v-if="item.nome_cliente">
                        Cliente: 
                      </span>
                      {{ item.nome_cliente }}
                    </span>
                    <span style="font-size: 12px; color: #808080 !important" class="mb-2 pr-10">
                      <span style="font-weight: bold" v-if="item.obra_endereco">
                        Endereço: 
                      </span>
                      {{ item.obra_endereco }}
                    </span>
                  </td>                      
                </tr>
              </template>
          </v-data-table>
        </v-container>
        <!-- Footer -->
        <v-container>
          <v-footer
            color="transparent"
            elevation="0"
            class="footer justify-center py-0"
          >
            <v-btn
              color="primary"
              class="btn mr-4 caption font-weight-medium"
              text
              @click="store_ModalCotacao.dialog_destinoConsulta = false"
              id="btn-cancelar"
              ref="btn-cancelar"
            >
              Cancelar
            </v-btn>            
            <v-btn
              :disabled="!valid"
              :loading="loading"
              @click="selecionaDestino(store_ModalCotacao.destino_selecionado)"
              class="btn white--text caption font-weight-medium"
              color="primary accent-4"
            >
              Selecionar
            </v-btn>
          </v-footer>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store_Obra from "../../Obra/store_Obra";
import store_ModalCotacao from "../../Cotacao/ModalCotacao/store_ModalCotacao";
import store_site from "../../../../store/store_site";
import store_Cotacao from "../../Cotacao/store_Cotacao";
import { API } from "../../../../services/API";
import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
} from "../../../../services/constantes";
import axios from "axios";

export default {
  name: "ModalDestinoConsulta",
  data() {
    return {
      store_Obra        : store_Obra,
      store_site        : store_site,
      store_Cotacao     : store_Cotacao,
      store_ModalCotacao: store_ModalCotacao,

      loading           : false,
      search            : null,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,

      valid             : true,
      selecionado       : [],

      headers: [
        { text: "", },
        { text: "infoObra",  value: "infoObra", },
      ],
    };
  },

  async created() {
    this.busca();
  },

  mounted() {
    // this.store_ModalCotacao.destino_selecionado = [...this.store_Cotacao.cotacao_selecionada.destino];
    // console.log("Destino Selecionado: ", this.store_ModalCotacao.destino_selecionado);

    // this.store_ModalCotacao.destino_selecionado.forEach((destino) => {
    //   console.log("Destino: ", destino);
    //   const index = this.store_Obra.dados.findIndex(
    //     (item) => item.cod_obra === destino.cod_obra
    //   );
    //     console.log("Index: ", index);
    //   if (index !== -1) {
    //   }
    // });
  },

  computed: {
  },

  watch: {
  },

  methods: {
    async busca() {
      this.loading = true;
      var lo_params = {...lo_params, situacao: "Em Andamento"}
      // console.log("Parâmetros de busca: ", lo_params)
      let lo_Res 
      lo_Res = await this.store_Obra.ObrasGet(lo_params);
      // console.log("Resultado da busca: ", lo_Res)
      this.loading = false;
    },


    seleciona(value, item) {
      if (value) {
        this.store_ModalCotacao.destino_selecionado = item;
      } else {
        this.store_ModalCotacao.destino_selecionado = [];
      }
      // if (value) {
      //   this.store_ModalCotacao.destino_selecionado.push(item);
      // } else {
      //   const index = this.store_ModalCotacao.destino_selecionado.findIndex(
      //     (destino) => destino.cod_obra === item.cod_obra
      //   );
      //   if (index !== -1) {
      //     this.store_ModalCotacao.destino_selecionado.splice(index, 1);
      //   }
      // }
      // console.log("Selecionado: ", this.store_ModalCotacao.destino_selecionado);
    },

    async selecionaDestino(destino) {
      this.loading = true;
      // this.store_Cotacao.cotacao_selecionada.destino = [destino];
      
      this.store_ModalCotacao.dialog_destinoConsulta = false;
      this.store_ModalCotacao.dialog_selecaoDestino = false;
      this.loading = false;
      console.log("Destino Selecionado: ", this.store_Cotacao.cotacao_selecionada.destino);
    },
  }
};
</script>
<style scoped>
.container {
  background: #f2f6f7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
}

.search-input {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
}

.search-input-longo {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
  /* min-width: calc(100% - 15px); */
}

  @media (max-width: 768px) {
    .search-input {
      margin: 0.8rem 10% 1.2rem 0;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      /* min-width: calc(100% - 15px); */
    }
  }

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
}
</style>
