<template>
  <div
    id="CotacaoContainer"
    class="container-fundo pa-0"
    v-bind:style="{ 'background-color': COR_PRINCIPAL }"
  >
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title
        class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0"
      >
        <!-- Botão Voltar -->
        <router-link
          :to="{ name: 'CotacaoConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action">
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </router-link>
        Menu de Compra
      </v-toolbar-title>
    </v-toolbar-title>

    <!-- Container - Cabeçalho Info. Cotação / Menu Navegação / Container Abas -->
    <v-container class="container rounded-lg mt-n10 pa-0">
      <v-card elevation="0" class="card d-flex flex-column pb-0">
        <v-card-text class="card-content my-0 py-0">
          <!-- Cabeçalho Info. Cotação - Avatar / Nome / Demais Dados-->
          <v-row class="d-none d-sm-block wrapper-top my-0 py-0 px1">
            <v-col class="col-top rounded-t-lg ma-0 pa-0">
              <v-sheet class="cabecalho d-flex align-center mx-auto px-4 py-2">
                <!-- Avatar da obra -->
                <div class="d-none d-sm-flex justify-center pr-4">
                  <!-- <v-avatar rounded size="80px" class="grey lighten-2">
                    <span
                      v-if="!store_Cotacao.cotacao_selecionada.foto_obra"
                      color="#274ABB"
                      class="headline"
                    >
                      {{ nomeIniciais(store_Cotacao.cotacao_selecionada.nome_obra) }}
                    </span>
                    <img
                      v-if="store_Cotacao.cotacao_selecionada.foto_obra"
                      :src="store_Cotacao.cotacao_selecionada.foto_obra"
                      alt="avatar"
                    />
                  </v-avatar> -->
                </div>
                <!-- Nome da Obra -->
                <v-toolbar-title
                  class="text-wrap title-page body-1 d-inline-flex align-center font-weight-bold"
                  :style="{ color: COR_PRINCIPAL, width: `400px !important` }"
                >
                  {{ store_Cotacao.cotacao_selecionada.nome_cotacao }}
                </v-toolbar-title>

                <!-- divider vertical -->
                <v-divider vertical class="divisor-vertical px-2"></v-divider>

                <!-- Campo Informações Obra -->
                <v-col class="d-flex flex-column pl-4">
                  <!-- 1ª Linha - Codigo / dt_criacao / dt_vencimento-->
                  <v-row no-gutters class="d-flex align-center mb-2 ma-0">
                    <!-- Codigo -->
                    <span
                      class="header-text body-2 font-weight-medium mr-3"
                      :style="{ color: COR_PRINCIPAL }"
                    >
                      Código:
                      <span :style="{ color: COR_SUBTITULO }">
                        {{ store_Cotacao.cotacao_selecionada.cod_cotacao }}
                      </span>
                    </span>
                    <!-- Campo Data -->
                    <span
                      style="white-space: nowrap"
                      class="header-text body-2 font-weight-medium mr-3"
                      :style="{ color: COR_PRINCIPAL }"
                    >
                      Data de Criação:
                      <span v-bind:style="{ color: COR_SUBTITULO }">
                        {{
                          store_Cotacao.cotacao_selecionada.dt_criacao
                            ? formatDate(
                                store_Cotacao.cotacao_selecionada.dt_criacao
                              )
                            : ""
                        }}
                      </span>
                    </span>
                    <!-- Campo Data -->
                    <span
                      style="white-space: nowrap"
                      class="header-text body-2 font-weight-medium mr-3"
                      :style="{ color: COR_PRINCIPAL }"
                    >
                      Data de Vencimento:
                      <span v-bind:style="{ color: COR_SUBTITULO }">
                        {{
                          store_Cotacao.cotacao_selecionada.dt_vencimento
                            ? formatDate(
                                store_Cotacao.cotacao_selecionada.dt_vencimento
                              )
                            : ""
                        }}
                      </span>
                    </span>
                  </v-row>

                  <!-- 2ª Linha - Situação / Destino / Fornecedor -->
                  <v-row no-gutters class="d-flex align-center ma-0">
                    <!-- Destino -->
                    <span
                      style="white-space: nowrap"
                      class="header-text body-2 font-weight-medium mr-3"
                      :style="{ color: COR_PRINCIPAL }"
                    >
                      Destino:
                      <span v-bind:style="{ color: COR_SUBTITULO }">
                        <!-- {{
                          store_Cotacao.cotacao_selecionada.destino
                            ? store_Cotacao.cotacao_selecionada.destino
                            : ""
                        }} -->
                      </span>
                    </span>
                    <!-- Fornecedor -->
                    <span
                      style="white-space: nowrap"
                      class="header-text body-2 font-weight-medium mr-3"
                      :style="{ color: COR_PRINCIPAL }"
                    >
                      Fornecedor:
                      <span v-bind:style="{ color: COR_SUBTITULO }">
                        <!-- {{
                          store_Cotacao.cotacao_selecionada.fornecedor
                            ? store_Cotacao.cotacao_selecionada.fornecedor
                            : ""
                        }} -->
                      </span>
                    </span>
                    <span
                      class="header-text mr-3 "
                      :style="{ color: COR_PRINCIPAL }"
                    >
                      Situação:
                      <span
                        v-if="store_Cotacao?.cotacao_selecionada.situacao == 'Em preenchimento'"
                        class="status_preenchimento"
                        >{{ store_Cotacao?.cotacao_selecionada.situacao }}
                      </span>

                      <!-- Em Andamento -->
                      <span
                        v-if="store_Cotacao?.cotacao_selecionada.situacao == 'Aguardando retorno'"
                        class="status_aguardando"
                        >{{ store_Cotacao?.cotacao_selecionada.situacao }}
                      </span>

                      <!-- Parada -->
                      <span
                        v-if="store_Cotacao?.cotacao_selecionada.situacao == 'Aguardando análise'"
                        class="status_aguardando"
                        >{{ store_Cotacao?.cotacao_selecionada.situacao }}
                      </span>

                      <span
                        v-if="store_Cotacao?.cotacao_selecionada.situacao == 'Pedido concluído'"
                        class="status_concluido"
                        >{{ store_Cotacao?.cotacao_selecionada.situacao }}
                      </span>
                    </span>
                  </v-row>
                </v-col>
                <!-- divisor para o botão opções -->
                <v-divider vertical class="divisor-vertical"></v-divider>
              </v-sheet>
              <v-divider class="divisor" style="width: 100%"></v-divider>
            </v-col>
          </v-row>

          <v-row class="wrapper-bottom my-0 py-0 px-1">
            <v-col
              class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0"
            >
              <!-- Dados Menu-Abas -->
              <v-list
                v-show="expand"
                v-if="expand"
                class="list rounded-l-lg overflow-y-scroll pt-1"
                dense
              >
                <v-list-item-group
                  v-model="itemSelecionado"
                  active-class="bg-active"
                  mandatory
                >
                  <!-- Chama os dados da lista de store_Cotacao.abas e coloca separador entre eles -->
                  <template v-for="[icon, text] in store_Cotacao.abas">
                    <!-- Separador -->
                    <v-divider v-if="text == 'separador'" :key="icon" class="my-1"/>

                    <v-list-item
                      v-else
                      :key="icon"
                      :disabled="store_Cotacao.acao != 'C'"
                    >
                      <!-- Exibe icones -->
                      <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                        <v-icon class="size-icon">{{ icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content
                        v-if="text != 'separador'"
                        class="mr-n2"
                      >
                        <!-- Icone de Ok -->
                        <!-- <v-badge
                          v-if="quant && quant == 'OK'"
                          icon="mdi-check"
                          :value="quant"
                          color="green accent-9"
                          offset-x="20"
                          offset-y="18"
                        >
                        </v-badge> -->

                        <!-- icone de erro -->
                        <!-- <v-badge
                          v-else-if="quant && Number(quant) > 0"
                          :content="quant"
                          :value="quant"
                          color="red accent-1"
                          offset-x="20"
                          offset-y="18"
                        >
                        </v-badge> -->
                        <v-list-item-title class="new-font">{{
                          text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              <!-- Exibe somente os Icones quando a lista é recuada -->
              <v-list v-else dense>
                <template v-for="[icon, text] in store_Cotacao.abas">
                  <v-divider
                    v-if="text == 'separador'"
                    :key="icon"
                    class="my-1"
                  />
                  <v-list-item
                    v-else
                    :key="icon"
                    :disabled="store_Cotacao.acao != 'C'"
                  >
                    <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                      <v-icon class="size-icon">{{ icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>

              <!-- Botão Expandir e Recuar aba esquerda -->
              <v-btn
                class="btn-expanded ma-0 pa-0"
                @click="expand = !expand"
                :style="`${expand ? '' : 'right: -10px!important;'}`"
              >
                <v-icon
                  v-if="expand == false"
                  class="size-icon-expanded ma-0 pa-0"
                  >mdi-chevron-right</v-icon
                >

                <v-icon v-else class="size-icon-expanded ma-0 pa-0"
                  >mdi-chevron-left</v-icon
                >
              </v-btn>
            </v-col>

            <v-divider class="divisor-vertical" vertical></v-divider>

            <!-- Coluna Conteudo Abas - Dados / Mapa / Pagamento -->
            <v-col
              v-if="itemSelecionado == 0"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <cotacao-cadastro />
            </v-col>
            <v-col
              v-if="itemSelecionado == 1"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
            </v-col>
            <v-col
              v-if="itemSelecionado == 2"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2"
            >
            </v-col>
            <!-- Tasks do Mobile -->
            <v-tabs-items
              v-if="isMobile"
              v-model="tab"
              class="ma-2 mb-5 mt-0"
              style="background-color: transparent"
            >
              <v-tab-item v-for="column in columns" :key="column.title">
                <div>{{ column.itemSelecionado }}</div>
              </v-tab-item>
            </v-tabs-items>
            <!-- FIM COL DIREITA ------------------------------->
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
} from "../../../services/constantes";
import store_Cotacao from "./store_Cotacao";
import store_usuario from "../../../store/store_usuario";
import CotacaoCadastro from "./CotacaoCadastro.vue";
import {
  formatNumber,
  maskCpfCnpj,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";
import { baseURL } from "../../../services/API";
// import GedDocumentos from "../../Ged/GedDocumentos.vue";

export default {
  components: {
    CotacaoCadastro,
  },

  data() {
    return {
      store_Cotacao: store_Cotacao,
      nomeIniciais: nomeIniciais,
      store_usuario: store_usuario,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SUBTITULO: COR_SUBTITULO,
      COR_SECUNDARIA: COR_SECUNDARIA,
      baseURL: baseURL,

      formatDate: formatDate,
      formatNumber: formatNumber,
      maskCpfCnpj: maskCpfCnpj,

      expand: true,
      tab: null,
      itemSelecionado: 0,

      columns: [],

      abas_mobile: [
        "Dados Básicos",
        "Orçamento",
        "Privilégios",
        "Dados Teste",
        "Documentos Teste",
        "Privilégios Teste",
      ],
    };
  },

  computed: {
    ImagemCapaCaminhoCompleto: function () {
      var img = "";
      // console.log(!!this.store_Cotacao.cotacao_selecionada.vendas_imovel?.imovel_imagem[0]);
      if (!!this.store_Cotacao.cotacao_selecionada?.vendas_imovel?.imovel_imagem[0]) {
        img =
          this.baseURL +
          "imagens/imoveis/fotos/empresa_" +
          this.store_usuario.user.cod_usuario +
          "/" +
          this.store_Cotacao.cotacao_selecionada?.vendas_imovel?.imovel_imagem[0]
            .imagem_caminho;
      }
      return img;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  async created() {
    var lo_params = { cod_cotacao: this.$route.params.cod_cotacao };
    if (lo_params.cod_cotacao) {
      this.store_Cotacao.acao = "C";
      this.store_Cotacao.cotacao_selecionada = {};
      const lo_Res = await this.store_Cotacao.CotacaoGet(lo_params);
      if (!!lo_Res) {
        console.log("lo_Res", lo_Res);
        this.store_Cotacao.cotacao_selecionada = { ...lo_Res };
      }
    }
    // Inclusão
    else {
      this.store_Cotacao.acao = "I";
      this.store_Cotacao.cotacao_selecionada = {
        cod_cotacao: null,
        nome_cotacao: null,
        dt_criacao: new Date().toISOString().split('T')[0] + 'T00:00:00.000-0300',
        dt_vencimento: null,
        situacao: "Em preenchimento",
        destino: [],
        fornecedor: [],
        cod_empresa: this.store_usuario.user.cod_empresa,
      };
    }
    console.log("store_Cotacao.cotacao_selecionada", this.store_Cotacao.cotacao_selecionada);
  },

  async mounted() {
    console.log(new Date().toISOString())
  },

  methods: {

    tamanho() {
      if (this.expand) return "180px";
      else return "1px";
    },
  },
};
</script>

<style scoped lang="scss">
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 17px !important;
}

@media (max-width: 599px) {
  .title-page {
    width: 100% !important;
  }

  .text-opacity {
    opacity: 0.7;
  }

  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #fff !important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100% !important;
  height: calc(100vh - 110px) !important;
}

@media (max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 160px) !important;
    margin-top: -4px !important;
  }
}

.card {
  height: 100% !important;
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA) !important;
}

.card-content {
  height: 100% !important;
}

.wrapper-top {
  height: 105px !important;
}

.header-text {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 105px) !important;
}

.divisor {
  height: 100%;
}

@media (max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column !important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor {
    display: none;
  }
}

.col-top {
  width: 100vw;
}

.col-esquerda {
  max-width: 300px !important;
  height: 100%;
  position: relative;
  background-color: #f7f7f7;
  margin-top: -2px;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  width: 70%;
}

@media (max-width: 599px) {
  .col-direita {
    padding-right: 4px !important;
    padding-left: 0px !important;
    width: 100%;
  }
}

.list {
  max-width: 300px !important;
  min-width: 240px !important;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

// .v-list-item-content
// .v-list-item-title {
//   color: #FF0000;
//   background-color: #FF0000;
//   padding-right: 5px!important;
// }

// .active_list .v-list-group .v-list-item--active {
//   color: #0000FF !important;
//   background-color: #FF0000;
// }

// .v-list-tile {
//   color: #FF0000;
//   background-color: #FF0000;
// }

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff !important;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL) !important;
  color: white !important;
}

.cabecalho {
  background-color: #f7f7f7;
}

@media (min-width: 900px) {
  .cabecalho {
    max-height: 100px;
    height: 100px !important;
    min-height: 100px;
  }
}
.divisor-vertical {
  // display: none;
  height: 100% !important;
  position: relative;
}

.status_concluido {
  font-size: 14px !important;
  background: #16c172;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_aguardando {
  font-size: 14px !important;
  background: #ffc857;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_preenchimento{
  font-size: 14px !important;
  background: #808080;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}
.custom-divider {
  height: 50px; /* Ajuste o valor conforme necessário */
}

.col-esquerda-collapsed {
  width: 50px; /* Ajuste conforme necessário */
  overflow: hidden;
}

.col-esquerda-expanded {
  width: 300px; /* Ajuste conforme necessário */
}
</style>
