import Vue from "vue";
import { API } from "../../../services/API"

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    obra_edicao: {},  // Obra Selecionada para Edição
    obra_selecionado: {}, 

    currentPage: 1,
    count_dados: null,

    // Filtro na Tela de Consulta 
    filtro: {
      cod_obra: null,
      cno: null,
      nome_obra: null,
      obra_endereco: null,
      quadra: null,
      lote: null,
      empreitada: null,
      valor_previsto_min: null,
      valor_previsto_max: null,
      valor_realizado_min: null,
      valor_realizado_max: null,
      dt_previsao_inicio: null,
      dt_previsao_termino: null,
      dt_realizacao_inicio: null,
      dt_realizacao_termino: null,
      situacao: null,
    },
    // Abas do container 
    abas: [
      ['mdi-monitor-dashboard', 'Resumo', '0'],
      ['mdi-text-box-outline', 'Dados', '0'],
      ['mdi-page-next-outline', 'Etapas', '0'],
      ['mdi-cash-multiple', 'Planilha Orçamentária', '0'],
      ['mdi-timer-sand-complete', 'Cronograma', '0'],
      ['mdi-folder-multiple-plus-outline', 'Documentos', '0'],
      ['mdi-text-box-multiple-outline', 'Relatorios', '0'],
    ],
  }
}

var vm_store_Obra = new Vue({

  data() {
    return initialState();
  },

  methods: {
    resetData() {
      Object.assign(this.$data, initialState());
    },

    // Métodos da Obra - Gets / Post / Put / Delete
    // Método - Get All
    async ObrasGet(p_params) {
      const resposta = await API.get("/obras", { params: { ...p_params } });
      // console.log('resposta', resposta)
      if (resposta) {
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          this.dados = resposta.data.result
          this.count_dados = resposta.data.result.length;
          return resposta.data.result
        }
      }
    },
    // Método - Get Individual
    async ObraGet(p_params) {
      const resposta = await API.get(`/obra`, { params: { ...p_params }});
      return resposta.data.result;
    },
    // Método - Post 
    async ObraPost(p_params) {
      const resp = await API.post(`/obras`, { params: { ...p_params }});
      return resp;
    },
    // Método - Put
    async ObraPut(p_params) {
      const resp = await API.put(`/obras/`, { params: { ...p_params }});
      return resp;
    },
    // Método - Delete
    async ObraDelete(p_params) {
      const resp = await API.delete(`/obras`, { params: { ...p_params }});
      return resp;
    },

    // Métodos de Endereços - Delete
    // Método - Delete
    async EnderecoDelete(p_params) {
      const resp = await API.delete(`/obras/enderecos/${p_params.cod_endereco}`);
      return resp;
    },

    // Métodos do Insumo - Gets / Post / Put / Delete
    // Método - Get All 
    async InsumoPost(body) {
      const resp = await API.post(`/insumos`, JSON.stringify(body));
      return resp;
    },

    // Métodos de Consulta de Documentos GED
    // Tela de Documentos ==================================================================
    async ObraGedGet(p_params) {
      const resposta = await API.get("obra_ged", {
        params: {
          ...p_params
        },
      }
      );
      // console.log(p_params, resposta)

      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          return resposta.data.result
        }
      }
    },

    async ObraGedDelete(body) {
      // console.log(body);
      // return
      const resposta = await API.delete(`ged_tipo_documento/${body.cod_ged}`)

      const { data } = resposta;
      if (resposta.status == 200)
        return data.result;
      else
        return data;

    },

  },
  
  watch: {
    // docDocumentos()           { this.atualiza_abas() },
    // docContrato()             { this.atualiza_abas() },
    // docTransferencia()        { this.atualiza_abas() },
    // docRenogociacao()         { this.atualiza_abas() },
    // docRescisao()             { this.atualiza_abas() },
    // docAutorizacaoEscritura() { this.atualiza_abas() },
    // docEscritura()            { this.atualiza_abas() },
    // docMensagens()            { this.atualiza_abas() }
  },
})

export default vm_store_Obra
