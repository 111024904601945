import Vue from "vue";
import { API } from "../../../../services/API"

function initialState() {
  return {
    acao: "I", // E=Edicao, I=Inclusão C=Consulta
    destino_selecionado: [],

    dialog_selecaoDestino : false,
    dialog_destinoConsulta: false,
  }
}

var vm_store_ModalCotacao = new Vue({
  data() {
    return initialState();
  },

  methods: {
    resetData() {
      Object.assign(this.$data, initialState());
    },

    async EnderecoPost(p_params) {
      const resp = await API.post(`/obras/enderecos`, p_params);
      return resp;
    },
    
    async EnderecoPut(p_params) {
      const resp = await API.put(`/obras/enderecos/${p_params.cod_endereco}`, p_params);
      return resp;
    },
  }
})

export default vm_store_ModalCotacao